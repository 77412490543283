body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 0;
  overflow-x: hidden;
  background-image: url('databackground.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
}

header {
  background-color: rgba(41, 43, 44, 0.8);
  color: #fff;
  padding: 20px;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  animation: moveHeader 1s infinite alternate;
}

@keyframes moveHeader {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}

.logo {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.main-content {
  max-width: 800px;
  margin: 100px auto 20px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

footer {
  background-color: rgba(41, 43, 44, 0.8);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.running-man {
  position: absolute;
  bottom: 20px;
  left: -100px;
  width: 150px;
  height: 100px;
  background: url('runningman.gif') no-repeat;
  background-size: contain;
  animation: runAnimation 5s linear infinite;
}

@keyframes runAnimation {
  to {
    left: 100%;
  }
}

.envelope-icon {
  font-size: 24px;
  margin-left: 5px;
  animation: moveEnvelope 1s infinite alternate;
}

@keyframes moveEnvelope {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(5px);
  }
}

.flashing-sign {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  animation: flashAnimation 1s ease-in-out infinite;
}

@keyframes flashAnimation {
  0%, 100% {
    background-color: red;
    color: white;
  }
  50% {
    background-color: white;
    color: red;
  }
}

.gold-cup-icon {
  font-size: 32px;
  margin-left: 10px; /* Adjust the margin as needed */
}

.matias-photo {
  width: 100px; /* Adjust the size as needed */
  border-radius: 50%;
  margin-top: 10px;
  margin-right: 10px; /* Adjust the margin as needed */
}

.middle-content {
  text-align: center;
  margin: 20px 0;
}

.flashing-sign-middle {
  background-color: red;
  color: white;
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
  display: inline-block;
  animation: flashAnimationMiddle 1s ease-in-out infinite;
}

@keyframes flashAnimationMiddle {
  0%, 100% {
    background-color: red;
    color: white;
  }
  50% {
    background-color: white;
    color: red;
  }
}

.currency-quoter {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.currency-quoter select {
  margin: 10px 0;
  padding: 5px;
  font-size: 16px;
}

.currency-quoter h3 {
  margin: 10px 0;
}

.currency-quoter p {
  font-size: 18px;
  font-weight: bold;
}
